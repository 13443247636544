@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply bg-even dark:bg-darkBg min-h-screen;
    min-width: 1366px;
  }
  h1 {
    @apply text-textPrimary dark:text-white text-lg font-semibold;
  }
  h2 {
    @apply text-lg font-semibold dark:text-darkTextTertiary;
  }
  h3 {
    @apply text-lg font-semibold  text-textPrimary dark:text-white;
  }
}

@layer components {
  .btn {
    @apply text-lg font-semibold bg-borderTertiary rounded-md p-3 text-center;
  }
  .icon-wrapper {
    @apply block py-2 px-4 rounded-md flex cursor-pointer items-center;
  }
  .status {
    @apply px-2 py-1 font-medium text-base bg-successBg flex items-center rounded;
  }
  .disabled-state {
    @apply cursor-not-allowed pointer-events-none;
  }
  /* Table styles */
  .table-wrapper {
    @apply w-full list-none border-2 border-solid border-iconBg;
  }
  .table-heading {
    @apply w-full flex flex-row bg-lightBlue;
  }
  .table-heading-content {
    @apply w-3/12 text-sm p-2 font-semibold text-textBlue;
  }
  .table-row-heading {
    @apply w-full flex flex-row bg-odd;
  }
  .table-d-content {
    @apply w-3/12 p-2;
  }

  /* Status report */
  .status-item {
    @apply p-2 cursor-pointer hover:bg-even dark:hover:bg-darkBorderPrimary dark:text-darkTextPrimary;
  }

  /* Rank style */
  .rank {
    @apply bg-lightGreen border-lightGreen dark:bg-darkContentBg border dark:border-green rounded mt-2 py-1 px-3;
  }

  code {
    color: #3a3a3a;
    background: #fffbfb;
    padding: 0 5px;
    border-radius: 4px;
  }

  .truncate-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }
}

:root {
  --toastify-toast-width: 400px;
  --toastify-color-warning: #e27a19;
}

input[type="checkbox"] {
  accent-color: #ED1C24;
}